<template>
  <v-fade-transition>
    <svg
      v-show="currentPage === 'mainMenu'"
      id="svg8"
      width="140.94mm"
      height="65.193mm"
      version="1.1"
      viewBox="0 0 140.94 65.193"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:osb="http://www.openswatchbook.org/uri/2009/osb"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs id="defs2">
        <radialGradient
          id="radialGradient849"
          cx="74.868"
          cy="133.57"
          r="25.229"
          gradientTransform="matrix(1.1519 .0053201 -.0052436 1.1353 29.05 -85.911)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            id="stop843"
            stop-color="#e8e8e8"
            stop-opacity="0"
            offset="0"
          />
          <stop
            id="stop857"
            stop-color="#82ecff"
            stop-opacity="0"
            offset=".73226"
          />
          <stop
            id="stop2565"
            stop-color="#56e7ff"
            stop-opacity=".019608"
            offset=".79402"
          />
          <stop
            id="stop859"
            stop-color="#2be4ff"
            stop-opacity=".19608"
            offset=".88142"
          />
          <stop
            id="stop845"
            stop-color="#dffbff"
            offset="1"
          />
        </radialGradient>
        <linearGradient
          id="linearGradient2378"
          x1="116.35"
          x2="103.81"
          y1="106.83"
          y2="99.656"
          gradientTransform="translate(-1.7634 -40.7)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            id="stop2369"
            stop-color="#e8e8e8"
            stop-opacity="0"
            offset="0"
          />
          <stop
            id="stop2380"
            stop-color="#e8e8e8"
            stop-opacity=".0078431"
            offset=".71818"
          />
          <stop
            id="stop2371"
            stop-color="#2be4ff"
            stop-opacity=".019608"
            offset=".84651"
          />
          <stop
            id="stop2373"
            stop-color="#7eefff"
            stop-opacity=".19608"
            offset=".94134"
          />
          <stop
            id="stop2375"
            stop-color="#fff"
            offset="1"
          />
        </linearGradient>
        <linearGradient
          id="linearGradient2378-5"
          x1="116.35"
          x2="128.9"
          y1="106.9"
          y2="99.65"
          gradientTransform="translate(-1.7634 -40.766)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            id="stop2499"
            stop-color="#e8e8e8"
            stop-opacity="0"
            offset="0"
          />
          <stop
            id="stop2501"
            stop-color="#e8e8e8"
            stop-opacity=".0078431"
            offset=".79339"
          />
          <stop
            id="stop2503"
            stop-color="#2be4ff"
            stop-opacity=".019608"
            offset=".88354"
          />
          <stop
            id="stop2505"
            stop-color="#7eefff"
            stop-opacity=".19608"
            offset=".95865"
          />
          <stop
            id="stop2507"
            stop-color="#fff"
            offset="1"
          />
        </linearGradient>
        <linearGradient
          id="linearGradient2378-5-2"
          x1="116.35"
          x2="116.35"
          y1="106.83"
          y2="121.62"
          gradientTransform="translate(-1.7634 -40.7)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            id="stop2513"
            stop-color="#e8e8e8"
            stop-opacity="0"
            offset="0"
          />
          <stop
            id="stop2515"
            stop-color="#e8e8e8"
            stop-opacity=".0078431"
            offset=".75379"
          />
          <stop
            id="stop2517"
            stop-color="#2be4ff"
            stop-opacity=".019608"
            offset=".84985"
          />
          <stop
            id="stop2519"
            stop-color="#7eefff"
            stop-opacity=".19608"
            offset=".95384"
          />
          <stop
            id="stop2521"
            stop-color="#fff"
            offset="1"
          />
        </linearGradient>
        <linearGradient
          id="linearGradient2629"
          x1="58.089"
          x2="102.43"
          y1="69.22"
          y2="58.704"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            id="stop2625"
            stop-color="#fff"
            stop-opacity="0"
            offset="0"
          />
          <stop
            id="stop2635"
            stop-color="#fff"
            stop-opacity="0"
            offset=".11694"
          />
          <stop
            id="stop2633"
            stop-color="#fff"
            stop-opacity=".28141"
            offset=".51397"
          />
          <stop
            id="stop2623"
            stop-color="#fff"
            offset="1"
          />
        </linearGradient>
        <linearGradient
          id="linearGradient2621-5"
          x1="113.38"
          x2="113.72"
          y1="63.051"
          y2="53.799"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            id="stop2663"
            stop-color="#54478c"
            stop-opacity=".60784"
            offset="0"
          />
          <stop
            id="stop2675"
            stop-color="#048ba8"
            stop-opacity=".62745"
            offset=".25"
          />
          <stop
            id="stop2669"
            stop-color="#b9e769"
            stop-opacity=".56471"
            offset=".5"
          />
          <stop
            id="stop2671"
            stop-color="#efea5a"
            stop-opacity=".59216"
            offset=".65"
          />
          <stop
            id="stop2665"
            stop-color="#f29e4c"
            stop-opacity=".61961"
            offset="1"
          />
        </linearGradient>
        <radialGradient
          id="radialGradient2691"
          cx="102.22"
          cy="61.745"
          r="13.515"
          gradientTransform="matrix(2.2928 -.0093623 .0024719 .60537 -132.3 22.715)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            id="stop2615"
            stop-color="#fff"
            offset="0"
          />
          <stop
            id="stop2617"
            stop-color="#fff"
            stop-opacity="0"
            offset="1"
          />
        </radialGradient>
        <radialGradient
          id="radialGradient2865"
          cx="124.93"
          cy="62.48"
          r="17.128"
          gradientTransform="matrix(2.8485 -.78785 1.4317 2.5677 -319.91 -5.2344)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            id="stop2777"
            stop-color="#f29e4c"
            stop-opacity=".78894"
            offset="0"
          />
          <stop
            id="stop2779"
            stop-color="#f29e4c"
            stop-opacity="0"
            offset="1"
          />
        </radialGradient>
        <radialGradient
          id="radialGradient2867"
          cx="126.53"
          cy="67.608"
          r="17.583"
          gradientTransform="matrix(2.8752 -.5142 .90842 2.5197 -297.28 -45.115)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            id="stop2841"
            stop-color="#b9e769"
            stop-opacity=".78824"
            offset="0"
          />
          <stop
            id="stop2843"
            stop-color="#b9e769"
            stop-opacity="0"
            offset="1"
          />
        </radialGradient>
        <radialGradient
          id="radialGradient2869"
          cx="128.25"
          cy="70.077"
          r="17.571"
          gradientTransform="matrix(2.9458 -.17084 .31124 2.6621 -268.94 -101.36)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            id="stop2853"
            stop-color="#048ba8"
            stop-opacity=".78824"
            offset="0"
          />
          <stop
            id="stop2855"
            stop-color="#048ba8"
            stop-opacity="0"
            offset="1"
          />
        </radialGradient>
        <radialGradient
          id="radialGradient2871"
          cx="130.3"
          cy="63.984"
          r="16.798"
          gradientTransform="matrix(2.8629 .094598 -.19062 2.8617 -226.91 -128.17)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            id="stop2859"
            stop-color="#54478c"
            stop-opacity=".78824"
            offset="0"
          />
          <stop
            id="stop2861"
            stop-color="#54478c"
            stop-opacity="0"
            offset="1"
          />
        </radialGradient>
      </defs>
      <metadata id="metadata5">
        <rdf:RDF>
          <cc:Work rdf:about="">
            <dc:format>image/svg+xml</dc:format>
            <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
            <dc:title />
          </cc:Work>
        </rdf:RDF>
      </metadata>
      <g
        id="layer1"
        transform="translate(-44.249 -23.395)"
      >
        <rect
          id="bgSquare"
          class="bgSquare"
          x="44.249"
          y="23.395"
          width="140.94"
          height="65.193"
        />
        <path
          id="triangleCircle"
          d="m89.72 80.786h50l-25-43.3-12.5 21.65z"
          fill="url(#radialGradient849)"
        />
        <g>
          <path
            id="triangleA"
            d="m89.72 80.786h50l-25-43.3-12.5 21.65z"
            fill="url(#linearGradient2378)"
          />
          <path
            id="triangleB"
            d="m89.72 80.72h50l-25-43.3-12.5 21.65z"
            fill="url(#linearGradient2378-5)"
          />
          <path
            id="triangleC"
            d="m89.72 80.786h50l-25-43.3-12.5 21.65z"
            fill="url(#linearGradient2378-5-2)"
          />
        </g>
      </g>
      <g
        id="colors"
        class="colors"
      >
        <path
          id="rect2612"
          transform="translate(-44.249 -23.395)"
          d="m55.451 69.338 47.053-10.662-1.2037 2.1561-45.872 10.329z"
          fill="url(#linearGradient2629)"
        />
        <path
          id="dispersionA"
          transform="translate(-44.249 -23.395)"
          d="m101.23 60.847 1.2509-2.1665 21.655-4.8817 7.2266 12.516z"
          fill="url(#linearGradient2621-5)"
          style="transition: all 0.5s;"
        />
        <path
          id="dispersionB"
          transform="translate(-44.249 -23.395)"
          d="m101.23 60.847 1.2509-2.1665 21.655-4.8817 7.2266 12.516z"
          fill="url(#radialGradient2691)"
        />
        <g
          id="textColors"
          class="textColors"
        >
          <g
            id="about"
            class="about textColor"
            @click="goToPage('aboutPage')"
          >
            <path
              id="orange"
              class="orange color"
              transform="translate(-44.249 -23.395)"
              d="m125.95 56.916-1.8163-3.1175 48.835-13.386 5.4111 6.8824z"
              fill="url(#radialGradient2865)"
            />
            <text
              id="aboutText"
              class="aboutText"
              transform="rotate(-12.333)"
              x="86.722054"
              y="51.121693"
              fill="#000000"
              font-family="'Josefin Sans'"
              font-size="4.2333px"
              letter-spacing="0px"
              stroke-width=".26458"
              word-spacing="0px"
              style="line-height:1.25"
              xml:space="preserve"
            ><tspan
              id="tspan904"
              x="87.522054"
              y="49.821693"
              font-size="4.0333px"
              stroke-width=".26458"
            >ABOUT</tspan></text>
          </g>
          <g
            id="games"
            class="games textColor"
            @click="goToPage('projectsPage')"
          >
            <path
              id="green"
              class="green color"
              transform="translate(-44.249 -23.395)"
              d="m127.77 60.034-1.8163-3.1175 52.43-9.6212 3.8056 7.2966z"
              fill="url(#radialGradient2867)"
            />
            <text
              id="gamesText"
              class="gamesText"
              transform="rotate(-9.018)"
              x="88.787315"
              y="51.542953"
              fill="#000000"
              font-family="'Josefin Sans'"
              font-size="4.2333px"
              letter-spacing="0px"
              stroke-width=".26458"
              word-spacing="0px"
              style="line-height:1.25"
              xml:space="preserve"
            ><tspan
              id="tspan908"
              x="89.787315"
              y="49.442953"
              font-size="4.0333px"
              stroke-width=".26458"
            >PROJECTS</tspan></text>
          </g>
          <g
            id="blog"
            class="blog textColor"
            @click="goToPage('blogPage')"
          >
            <path
              id="blue"
              class="blue color"
              transform="translate(-44.249 -23.395)"
              d="m129.59 63.151-1.8163-3.1175 54.419-5.4421 2.3699 7.6647z"
              fill="url(#radialGradient2869)"
            />
            <text
              id="blogText"
              class="blogText"
              transform="rotate(-2.2893)"
              x="92.655106"
              y="49.486526"
              fill="#000000"
              font-family="'Josefin Sans'"
              font-size="4.6333px"
              letter-spacing="0px"
              stroke-width=".26458"
              word-spacing="0px"
              style="line-height:1.25"
              xml:space="preserve"
            ><tspan
              id="tspan912"
              x="95.355106"
              y="42.886526"
              font-size="4.0333px"
              stroke-width=".26458"
            >BLOG</tspan></text>
          </g>
          <g
            id="contact"
            class="contact textColor"
            @click="goToPage('contactPage')"
          >
            <path
              id="purple"
              class="purple color"
              transform="translate(-44.249 -23.395)"
              d="m131.36 66.315-1.7777-3.1639 54.973-0.89489 0.6311 9.3147z"
              fill="url(#radialGradient2871)"
            />
            <text
              id="contactText"
              class="contactText"
              transform="rotate(3.3441)"
              x="98.406548"
              y="37.375965"
              fill="#000000"
              font-family="'Josefin Sans'"
              font-size="4.2333px"
              letter-spacing="0px"
              stroke-width=".26458"
              word-spacing="0px"
              style="line-height:1.25"
              xml:space="preserve"
            ><tspan
              id="tspan916"
              x="98.906548"
              y="37.675965"
              font-size="4.0333px"
              stroke-width=".26458"
            >CONTACT</tspan></text>
          </g>
        </g>
      </g>
    </svg>
  </v-fade-transition>
</template>

<script>
  export default {

    props: {
      currentPage: String,
    },

    data () {
      return {
        time: 5,
      }
    },
    mounted () {
      this.AnimateLight()
    },
    methods: {
      async AnimateLight () {
        await new Promise(resolve => setTimeout(resolve, 50))
        const colors = document.getElementById('colors')
        colors.classList.remove('colors')
        if (this.$vuetify.breakpoint.smAndDown) {
          colors.classList.add('colorsSm')
          await new Promise(resolve => setTimeout(resolve, 50))
          colors.classList.remove('colorsSm')
        }
        if (this.$vuetify.breakpoint.md) {
          colors.classList.add('colorsMd')
          await new Promise(resolve => setTimeout(resolve, 50))
          colors.classList.remove('colorsMd')
        }
        colors.classList.add('colorsShown')
        await new Promise(resolve => setTimeout(resolve, 1000 * this.time))
        colors.classList.remove('colorsShown')
      },
      goToPage (page) {
        this.$emit('goToPage', page)
      },
    },
  }
</script>

<style>
.image {
  width: 100%;
  height: 80vh;
}

.colors {
  clip-path: circle(0% at 0% 100%);
}
.colorsSm {
  clip-path: circle(50% at 0% 100%);
}
.colorsMd {
  clip-path: circle(20% at 0% 100%);
}
.colorsShown {
  clip-path: circle(200% at 0% 100%);
  transition: all 2s ease-in-out;
}
.contactText {
  fill-opacity: 1 !important;
  pointer-events: none;
}
.gamesText {
  fill-opacity: 1 !important;
  pointer-events: none;
}
.aboutText {
  fill-opacity: 1 !important;
  pointer-events: none;
}
.blogText {
  fill-opacity: 1 !important;
  pointer-events: none;
}

.color {
  transition: all 0.2s ease-in-out;
  fill-opacity: 1;
  cursor: pointer;
}

.textColors:hover .color {
  fill-opacity: 0.5;
}

.textColors .color:hover {
  fill-opacity: 1;
}

.bgSquare {
  display: none;
}

</style>
